import colorbrewer from "colorbrewer";

const colsPrefix = "a.the_geom_webmercator, a.geogcode, a.geogname, a.ladcode, a.ladname, ";

const jointablesql = " a, psqm_msoa21_wide b where a.geogcode = b.msoa21 ";

const rampHP = colorbrewer.YlOrRd[9];

const legendStopsSales = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#888888", name: "No data" },
      { value: 0, colour: rampHP[0], name: "Under £1000/m²" },
      { value: 1000, colour: rampHP[1], name: "£1,000-£1,500/m²" },
      { value: 1500, colour: rampHP[2], name: "£1,500-£2,000/m²" },
      { value: 2000, colour: rampHP[3], name: "£2,000-£2,500/m²" },
      { value: 2500, colour: rampHP[4], name: "£2,500-£3,000/m²" },
      { value: 3000, colour: rampHP[5], name: "£3,000-£4,000/m²" },
      { value: 4000, colour: rampHP[6], name: "£4,000-£5,000/m²" },
      { value: 5000, colour: "#cd1126", name: "£5,000-£6,000/m²" },
      { value: 6000, colour: "#9c0026", name: "£6,000-£7,000/m²" },
      { value: 7000, colour: "#6c0020", name: "£7,000-£8,000/m²" },
      { value: 8000, colour: "#3c0016", name: "£8,000-£10,000/m²" },
      { value: 10000, colour: "#000000", name: "£10,000-£15,000/m²" },
      { value: 15000, colour: "#ff00ff", name: "£15,000/m²+" }
    ]
  }
];

const legendStopsRent = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#888888", name: "No data" },
      { value: 0, colour: rampHP[0], name: "Under £1.50/m²" },
      { value: 1.5, colour: rampHP[1], name: "£1.50-£1.75/m²" },
      { value: 1.75, colour: rampHP[2], name: "£1.75-£2.00/m²" },
      { value: 2, colour: rampHP[3], name: "£2.00-£2.25/m²" },
      { value: 2.25, colour: rampHP[4], name: "£2.25-£2.50/m²" },
      { value: 2.5, colour: rampHP[5], name: "£2.50-£2.75/m²" },
      { value: 2.75, colour: rampHP[6], name: "£2.75-£3.00/m²" },
      { value: 3, colour: "#cd1126", name: "£3.00-£3.50/m²" },
      { value: 3.5, colour: "#9c0026", name: "£3.50-£4.00/m²" },
      { value: 4, colour: "#6c0020", name: "£4.00-£5.00/m²" },
      { value: 5, colour: "#3c0016", name: "£5.00-£7.00/m²" },
      { value: 7, colour: "#000000", name: "£7.00-£10.00/m²" },
      { value: 10, colour: "#ff00ff", name: "£10.00/m²+" }
    ]
  }
];

const legendStopsCount = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#888888", name: "No data" },
      { value: 0, colour: rampHP[0], name: "Under 3" },
      { value: 3, colour: rampHP[1], name: "3 or 4" },
      { value: 5, colour: rampHP[2], name: "5-9" },
      { value: 10, colour: rampHP[3], name: "10-19" },
      { value: 20, colour: rampHP[4], name: "20-29" },
      { value: 30, colour: rampHP[5], name: "30-39" },
      { value: 40, colour: rampHP[6], name: "40-49" },
      { value: 50, colour: "#cd1126", name: "50-74" },
      { value: 75, colour: "#9c0026", name: "75-99" },
      { value: 100, colour: "#6c0020", name: "100-199" },
      { value: 200, colour: "#3c0016", name: "200-299" },
      { value: 300, colour: "#000000", name: "300-399" },
      { value: 400, colour: "#ff00ff", name: "400+" }
    ]
  }
];

function getCols() {
  return (
    colsPrefix +
    getMetricList()
      .map(function(a) {
        return a.field;
      })
      .join(", ")
  );
}

function getMetricList() {
  let list = [];
  let i = 0;

  const cats = {
    "sp_median": "Property Sale Price/m²",
    "rp_median": "Weekly Rent/m²",
    "scount": "Number of Property Sales",
    "rcount": "Number of Rentals"
  };

  const minYear = 2008;
  const maxYear = 2024;
  
  Object.keys(cats).forEach(function(key) {
    for (let j = minYear; j <= maxYear; j++) {
      if ((key == "rp_median" || key == "rcount") && (j < 2010 || j > 2020 )) continue;
      list.push({
        id: i,
        name: cats[key] + " (" + j + ")",
        field: "" + key + "_" + j,
        display: (key == "scount" || key == "rcount") ? "" : "£",
        decimals: key == "rp_median" ? 2 : 0,
        legendStops: key == "rp_median" ? legendStopsRent : key == "sp_median" ? legendStopsSales : legendStopsCount,
        legendOptions: []
      });
      i++;
    }
  });
  return list;
}

export const PRICEPERAREA = {
  title: "Rent and Sales Price Per Unit Area",
  description:
    "Median residential property sales and weekly rents per square metre, by year.",
  downloadLink: "",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + getCols() + " FROM msoa21_gb" + jointablesql,
  buildingSql: "SELECT " + getCols() + " FROM msoa21_gb_vmd23" + jointablesql,
  detailUrbanSql: "SELECT " + getCols() + " FROM msoa21_gb_bua24" + jointablesql,
  urbanSql: "SELECT " + getCols() + " FROM msoa21_gb_bua24" + jointablesql,
  defaultLayers: "1111000",
  initialValue: 0,
  extent: 10,
  popup: false,
  idField: "geogcode",
  geogNameField: "geogname",
  ladCodeField: "ladcode",
  hoverFieldMetricMode: "All",
  metrics: getMetricList()
};
